.splashScreen {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}