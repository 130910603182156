.player-wrapper {
  margin: auto;
  position: fixed;
  top: 0;
  z-index: -2;
  background-color: black;
}

.container-fluid {
  padding: 0;
}